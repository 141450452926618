const video=['wmv', 'mp4', 'mov','flv', 'mkv','avi', '3gp', 'mxf','mpg'];
const videoUpper = video.map(v=>v.toLocaleUpperCase())
const image =['bmp', 'jpeg', 'jpg','png'];
const imageUpper = image.map(v=>v.toLocaleUpperCase())
const version = '2.2.5'
const build = 6
export const privateKey = "MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALdWn9f2Q8kNJY6Q/D0MWrDjnAXUuRjfyY/b0pxzMeJ2oxOw48ObNqvFEGYkCslGx9OU53QN06C8BJ37YiuFxGD3ZSYIBOP+OqOW2AxiXQiNMVf1hbm1h82xprGu478Hz0bLk4rqGj3sWuQAIGyFG0qcBX8D7XMVW4ydRPeCngf/AgMBAAECgYANBbMe1NglvLBROw0fjPQYImKfq2dmU+buN+/yut/tNP4KVQMojqrIcpvilWbKuyNQbQAvpxfED/bH/eYQL1UuazHfI6O3ZyTCqGuGEs2iLUsOsXY4D0fJltyyIKt+yVDLj9axjGoCg8gbSUBFcx270w6BI+/wACGk3AZSy0nqaQJBAOSsqnhUm4E6Zan/4XQ2gbI6HdoHiM8/d3QjSnXczraUwO0jDUyXIEE/IZgv9DfaCd3nh0bCrhasROFFKWtk7B0CQQDNPxdVPLyzG09nTrV/rrHPZ2qxsCcHLYG0j5zSibDdKG+56LAKGIpLVCAKzYKCbLOxZSzI0ldxF9NUNt1YdnHLAkBq3XJRB6EgdEz8dDNFXw+fXd+De8/ooo1NfgyXJhiIZN4ANEhLybhuJXWqrIZh74h65m9mlyZKPDl4WRzxLlnVAkEAoqa89u+LGuUpp9TYQLdQceyiwO24ABgwEcc9oBs97gV9hPbBiz6nWcrxGZ6DLpi2Tn6/XuZSJW2cK8DO6Kv0wQJAZlxxZxnKE+TXaW0NlHESxQ9cq6vw8QfafbNI7gjvidlSuENV9Nme0QZ9Q7lOxJYtoibYJu93n/1DVqoNm8lZIQ==";
// 视频支持的格式
export const  videoAccept =[...video,...videoUpper];
// 图片支持的格式
export const  imageAccept =[...image];
// 最大视频上传大小 1G
export const  maxVideoSize = 1024 * 1024 *1024;
// 最大图片上传大小 20M
export const  maxImageSize = 20 * 1024 * 1024;
export const  maxUploadCount = 15;
export const  minWidth = 100;
export const  maxWidth = 2048;
export const termsofService = '/picma_web/termsofuse'
export const privacyPolicy = '/picma_web/privacypolicy'
export const cookiePolicy = '/picma_web/privacypolicy#cookies'
export const email = 'picma_support@magictiger.ai'
export const facebook = 'https://www.facebook.com/PicmaAI'
export const ins = 'https://www.instagram.com/picma_official/'
export const YouTube = 'https://www.youtube.com/@picma-aiphotoenhancer7771'
export const Twitter = 'https://twitter.com/PicmaAI'
export const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCqHiZg28n4r7t0MnB0qD5QIBa9ZPmHX5TL5KVIBuIQPqY+jjys3nt3DMvRpWqjqdDiwT0IyAX9LLq5Q1y48Q2GeT3UGbrMcc+kXcDldVx/D3WLLjYBSrHK4uHpzoSgKx7qfOXT/XFB1MjbTkdWTG59ubtHthVoYPNTRBscaweO1wIDAQAB"
export default {
    videoAccept,
    imageAccept,
    maxVideoSize,
    maxImageSize,
    minWidth,
    maxWidth,
    termsofService,
    privacyPolicy,
    cookiePolicy,
    email,
    facebook,
    ins,
    YouTube,
    Twitter,
    version,
    build,
    privateKey,
    publicKey
}